
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const meta = {
  title: 'Webhooks',
  category: 'Guides',
  published: true,
  sort: 5
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Webhooks`}</h1>
    <p>{`An important part of the `}<a parentName="p" {...{
        "href": "/docs/workflow-diagrams"
      }}>{`management of a loan project`}</a>{` business process includes asynchronous information flowing from GoodLeap to the client. This asynchronous communication is handled by our webhook framework where we require endpoint and Authorization information for the client system and send the following messages to that endpoint based on the business event occurring. Here is a list of the events and the callback message body for the supported webhooks.`}</p>
    <h2>{`ApplicationSubmitted`}</h2>
    <p>{`Application has been submitted to GoodLeap for processing`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "ApplicationSubmitted",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`ContractSigned`}</h2>
    <p>{`Confirmation response GoodLeap has received “Contract signed” from installer partner`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "ContractSigned",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`ContractUploaded`}</h2>
    <p>{`Confirmation response GoodLeap has received “Contract Uploaded” from installer partner`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "ContractUploaded",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`DocsCompleted`}</h2>
    <p>{`Confirmation response from GoodLeap that final system price and loan selections have been provided. Loan doc set to complete, they can no longer be voided via the api.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "DocsCompleted",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`DocsRequested`}</h2>
    <p>{`Confirmation response GoodLeap has received the request to generate and send loan docs to the consumer.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "DocsRequested",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`DocsSent`}</h2>
    <p>{`Confirmation response DocuSign has sent loan documents to the consumer for signing.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "DocsSent",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`DocsSigned`}</h2>
    <p>{`Confirmation response from DocuSign loan docs have been signed.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "DocsSigned",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`DocsSubmitted`}</h2>
    <p>{`Confirmation response GoodLeap has sent request to DocuSign to create and send loan docs.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "DocsSubmitted",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`DocsViewed`}</h2>
    <p>{`Confirmation response from DocuSign loan docs have been viewed by consumer. `}<em parentName="p">{`Notice: Depending on the lifecycle of the LoanDocs, This "event" isn"t always generated.`}</em></p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "DocsViewed",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`DocsVoided`}</h2>
    <p>{`Confirmation response from DocuSign that loan docs have been voided. This occurs when a price change or loan offer selection changes after the initial signing of loan docs, requiring the voiding of the originals and the creation of new docs.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "DocsVoided",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`FundingRequested`}</h2>
    <p>{`Confirmation response from GoodLeap that we have requested funding of the loan from our warehouse line.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "FundingRequested",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`InstallationComplete`}</h2>
    <p>{`Confirmation response from GoodLeap that we received the Installation Complete message from Installer Partner`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "InstallationComplete",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`LoanApproved`}</h2>
    <p>{`Application submitted to GoodLeap has returned the response “Approved”`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "LoanApproved",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`LoanApprovedWithStips`}</h2>
    <p>{`Application submitted to GoodLeap has returned the response “Approved with Stipulations” or CONDITIONAL.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "LoanApprovedWithStips",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456",
  "stipulations": [
    {
      "type": "Title",
      "internal": true
    },
    {
      "type": "Title",
      "internal": false
    }
  ]
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`LoanCanceled`}</h2>
    <p>{`Application was cancelled by the installer partner or at the borrower's request`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "LoanCanceled",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`LoanDeclined`}</h2>
    <p>{`Application submitted to GoodLeap has returned the response “Declined”`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "LoanDeclined",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`LoanFunded`}</h2>
    <p>{`Confirmation response from GoodLeap loan has been funded, funds have been transferred into installer partner"s bank account.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "LoanFunded",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`LoanPending`}</h2>
    <p>{`Application submitted to GoodLeap has returned the response Pending (usually due to a frozen credit file)`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "LoanPending",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`NtpComplete`}</h2>
    <p>{`System response from GoodLeap, NTP Complete has been granted.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "NtpComplete",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`PendingRecision`}</h2>
    <p>{`A loan is functionally ReadyToFund, but it hasn"t been 4 days since DocsSigned/DocsComplete`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "PendingRecision",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456",
  "dateToFund": "2017-10-21T00:00:00.000Z"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`PreScreenFailed`}</h2>
    <p>{`Application submitted to GoodLeap has failed prescreen “Active loan exists”`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "PreScreenFailed",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456",
  "reason": "activeloan"
}
`}</code></pre>
    </blockquote>
    <h2>{`PtoComplete`}</h2>
    <p>{`Confirmation response from GoodLeap that we received the PTO Complete message from installer partner`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "PtoComplete",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`ReadyToFund`}</h2>
    <p>{`Confirmation response from GoodLeap that Loan is ready to fund. Installation complete has been received, triggering ready to fund.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "ReadyToFund",
  "applicationId": "17-01-000001",
  "milestone": "1",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`SystemDescriptionUpdated`}</h2>
    <p>{`Confirmation response GoodLeap has received System Description from installer partner. This is equivalent to “Contract Uploaded”, but when the information is submitted via API.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "SystemDescriptionUpdated",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`SystemVerified`}</h2>
    <p>{`Confirmation response from GoodLeap that we received the System Verified information message from installer partner`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "SystemVerified",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`CompletedProjectCertificateRequested`}</h2>
    <p>{`Confirmation response GoodLeap has received the request to generate and the project certificate to the consumer.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "CompletedProjectCertificateRequested",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`CompletedProjectCertificateSent`}</h2>
    <p>{`Confirmation response DocuSign has sent the project certificate to the consumer for signing.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "CompletedProjectCertificateSent",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`CompletedProjectCertificateCompleted`}</h2>
    <p>{`Confirmation response from DocuSign the project certificate has been signed by consumer.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "CompletedProjectCertificateCompleted",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`CompletedProjectCertificateRejected`}</h2>
    <p>{`Confirmation response from DocuSign the project certificate has been rejected by consumer.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "CompletedProjectCertificateRejected",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`CompletedProjectCertificateDeclined`}</h2>
    <p>{`Confirmation response from DocuSign consumer declined to sign the project certificate.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "CompletedProjectCertificateDeclined",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    <hr></hr>
    <h2>{`CompletedProjectCertificateVoided`}</h2>
    <p>{`Confirmation response from DocuSign that the project certificate has been voided. This occurs when a price change, loan offer selection, project info, or install date changes after the initial project certificate is sent, requiring the voiding of the original and the creation of a new project certificate.`}</p>
    <blockquote>
      <pre parentName="blockquote"><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "event": "CompletedProjectCertificateVoided",
  "applicationId": "17-01-000001",
  "timestamp": "2017-10-18T21:33:30.209Z",
  "referenceNumber": "123456"
}
`}</code></pre>
    </blockquote>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;