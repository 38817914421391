
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const meta = {
  title: 'Coming Soon',
  category: 'Updates',
  published: false,
  sort: 3
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Coming Soon`}</h1>
    <p>{`This is where we will give a preview of upcoming features to be added to the latest version of the API. Here is what's on our immediate roadmap.`}</p>
    <h2>{`Future Releases`}</h2>
    <ul>
      <li parentName="ul">{`We are working to prioritize our backlog items and build them into a future sprint, help us determine what to build next by making a feature request below!`}</li>
    </ul>
    <h2>{`Feature Requests`}</h2>
    <p>{`If there's something you as a user would find useful, please don't hesitate to send us a `}<a parentName="p" {...{
        "href": "mailto:cfappsupport@goodleap.com?subject=API%20V2%20Feature%20Request&body=Installer%20Partner%20Name:%20%3C%3CINSERT%20PARTNER%20NAME%20HERE%3E%3E%0D%0ARelationship%20to%20Installer%20Partner:%20%3C%3CChoose%20one%20of%20the%20following:%20Partner%20Employee,%20Integrator%20for%20the%20Installer%20Partner,%20Other:%20Describe%20Relationship%3E%3E%0D%0A%0D%0AFeature%20Request:%20%3C%3CINSERT%20REQUEST%20HERE%3E%3E"
      }}>{`Feature Request`}</a>{`!`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;