
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const meta = {
  title: 'Overview',
  category: 'Guides',
  sort: 1,
  published: true
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Development Guides`}</h1>
    <p>{`This section of the documentation is intended to get you up-and-running with the GoodLeap API. We'll cover everything you need to know, from authentication to creating and managing loan applications through the API.`}</p>
    <p><a parentName="p" {...{
        "href": "/docs/authentication"
      }}>{`Authentication`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/docs/error"
      }}>{`Error Codes`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/docs/testing"
      }}>{`Testing`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/docs/webhooks"
      }}>{`Webhooks`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/docs/solar-battery-workflow"
      }}>{`Solar & Battery Workflow Code Samples`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/docs/hi-roofing-workflow"
      }}>{`Home Improvement & Roofing Workflow Code Samples`}</a></p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;