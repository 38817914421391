
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Table from '@loanpal/lumos__table';
export const meta = {
  title: 'Migration Station',
  category: 'Updates',
  published: true,
  sort: 1
};
export const data = [{
  description: 'Calculate monthly payments',
  v1Endpoint: 'GET /v1/loanCalc',
  v2Endpoint: 'GET /v2/payments'
}, {
  description: 'Get Categories',
  v1Endpoint: <strong>No Equivalent</strong>,
  v2Endpoint: 'GET /v2/categories'
}, {
  description: 'Get available loan offers',
  v1Endpoint: 'GET /v1/loanOptions',
  v2Endpoint: 'GET /v2/offers'
}, {
  description: 'Fetch disclosures',
  v1Endpoint: <strong>No Equivalent</strong>,
  v2Endpoint: 'GET /v2/disclosures'
}, {
  description: 'Fetch available promotions',
  v1Endpoint: <strong>No Equivalent</strong>,
  v2Endpoint: 'GET /v2/promotions'
}, {
  description: 'Submit a loan application',
  v1Endpoint: 'POST /v1/applications',
  v2Endpoint: 'POST /v2/loans'
}, {
  description: 'Fetch loan by ID',
  v1Endpoint: 'GET /v1/application/:loan-id',
  v2Endpoint: 'GET /v2/loans/:id'
}, {
  description: 'Fetch loan by reference number (returns a collection)',
  v1Endpoint: 'GET /v1/applications/reference/:reference-id',
  v2Endpoint: 'GET /v2/loans?referenceNumber='
}, {
  description: 'Cancel a loan',
  v1Endpoint: 'POST /v1/applications/:loan-id/cancel',
  v2Endpoint: 'POST /v2/loans/:id/cancellations'
}, {
  description: 'Submit a change order',
  v1Endpoint: 'PUT /v1/applications/:loan-id/loanAmountAndOption',
  v2Endpoint: 'POST /v2/loans/:id/changeOrder'
}, {
  description: 'Submit loan detail verification',
  v1Endpoint: <strong>No Equivalent</strong>,
  v2Endpoint: 'POST /v2/loans/:id/details'
}, {
  description: 'Send loan documents',
  v1Endpoint: 'POST /v1/applications/:loan-id/sendLoanDocs',
  v2Endpoint: 'GET /v2/loans/:id/documents'
}, {
  description: 'Get stipulations',
  v1Endpoint: <strong>No Equivalent</strong>,
  v2Endpoint: 'GET /loans/:id/stipulations'
}, {
  description: 'Upload stipulation artifact',
  v1Endpoint: <strong>No Equivalent</strong>,
  v2Endpoint: <div>
        GET /v2/loans/:id/uploadurl?stipId=1234&uploadType=STIP&docType=DRIVERS_LICENSE
        <br />
        returns a pre-signed URL for Driver's License Upload
      </div>
}, {
  description: 'Get current loan status',
  v1Endpoint: 'GET /v1/applications/:loan-id/status',
  v2Endpoint: 'GET /v2/loans/:id/status'
}, {
  description: 'Return project details of a loan',
  v1Endpoint: 'GET /v1/applications/:loan-id/system',
  v2Endpoint: 'GET /v2/loans/:id/project'
}, {
  description: 'Fetch available channels (option to filter by states)',
  v1Endpoint: 'GET /v1/sourceOptions',
  v2Endpoint: 'GET /v2/channels?state=‘’&states=[]'
}, {
  description: 'Fetch available states (option to filter by channels)',
  v1Endpoint: 'GET /v1/sourceOptions',
  v2Endpoint: 'GET /v2/states?channel=‘’&channels=[]'
}, {
  description: 'Upload HIC',
  v1Endpoint: 'POST /v1/applications/:loan-id/hic',
  v2Endpoint: <div>
        GET /v2/loans/:id/uploadurl?supplemental=true&#124;false&uploadType=HIC
        <br />
        returns a pre-signed URL for HIC
      </div>
}, {
  description: 'Fetch project component manufacturers',
  v1Endpoint: <strong>No Equivalent</strong>,
  v2Endpoint: 'GET /v2/categories/:id/components'
}, {
  description: 'Update the project details of a loan',
  v1Endpoint: 'POST /v1/applications/:loan-id/hic',
  v2Endpoint: 'PUT /v2/loans/:id/project'
}, {
  description: 'Update milestones',
  v1Endpoint: 'POST /v1/applications/:loan-id/milestones',
  v2Endpoint: 'POST /v2/loans/:id/milestones'
}];

const layoutProps = {
  meta,
data
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Migrating to API v2`}</h1>
    {/* TODO: styling, etc */}
    <p>{`The Developer API v2 offers great new features in comparison to the v1 API which allow users to:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Update Disclosures in the Loan Application`}</strong>{` - As our disclosures change, the update and compliance validation are completely manual processes that results (internally) in potentially unsaleable loans due to missed disclosure requirements.`}</li>
      <li parentName="ul"><strong parentName="li">{`Support Battery Retrofit Financing`}</strong>{` - The current API doesn’t have the correct actions to support our Battery Retrofit Financing.`}</li>
      <li parentName="ul"><strong parentName="li">{`Enable Soft Credit Pull`}</strong>{` - Hand in hand with updating our disclosures, we’ll be able to offer features like soft credit as well, since the feature requires the updated consumer disclosures.`}</li>
      <li parentName="ul"><strong parentName="li">{`Enable ACH Discount`}</strong>{` - API will support ACH Enrollment and it’s conditional interest rate/APR + monthly payment population in the loan docs.`}</li>
    </ul>
    <h2>{`Authentication Comparison`}</h2>
    <p>{`Instead of using an AWS Signature for authentication (supported in v1) Basic HTTP authentication is supported in API v2 for ease of use. Head over to the `}<a parentName="p" {...{
        "href": "/docs/authentication"
      }}>{`Authentication`}</a>{` for more details.`}</p>
    <h2>{`API v1 to API v2 Resource/Action Equivalency Map`}</h2>
    <Table columns={[{
      Header: 'Description',
      accessor: 'description'
    }, {
      Header: 'API V1 Endpoint',
      accessor: 'v1Endpoint'
    }, {
      Header: 'API V2 Endpoint',
      accessor: 'v2Endpoint'
    }]} data={data} mdxType="Table" />
    <h2>{`Get Loan Offers`}</h2>
    <p>{`API v2 introduces new resources and filtering to be able to control the loan offers available in certain markets and channels as well as `}<a parentName="p" {...{
        "href": "/docs/showcase-promotions"
      }}>{`promotions`}</a>{` for offering discounts to homeowners when available. Check out the `}<strong parentName="p">{`Submitting a Loan Application`}</strong>{` > `}<strong parentName="p">{`1. Present Loan Offers`}</strong>{` section on `}<a parentName="p" {...{
        "href": "/docs/workflow"
      }}>{`Workflow Code Samples`}</a>{` for a tutorial or investigate the `}<a parentName="p" {...{
        "href": "reference#operation/getRestV2Channels"
      }}>{`Get Channels`}</a>{`, `}<a parentName="p" {...{
        "href": "/reference#operation/getRestV2States"
      }}>{`Get States`}</a>{`, and query parameters in `}<a parentName="p" {...{
        "href": "/reference#operation/getRestV2Offers"
      }}>{`Get Offers`}</a>{` in the API documentation for yourself.`}</p>
    <h2>{`Disclosures`}</h2>
    <p>{`To support future changes within the GoodLeap required disclosures without having to update your loan application form directly, we're making our disclosures available via an API. Check out the `}<a parentName="p" {...{
        "href": "/docs/workflow"
      }}>{`Workflow Code Samples`}</a>{` > `}<strong parentName="p">{`Submitting a Loan Application`}</strong>{` > `}<strong parentName="p">{`Section 2. Capture Disclosures`}</strong>{` for more information and see `}<a parentName="p" {...{
        "href": "/reference#operation/getRestV2Disclosures"
      }}>{`Get Disclosures`}</a>{` in the API documentation for technical implementation info.`}</p>
    <h2>{`Webhooks`}</h2>
    <p>{`API v2 be adding support for multi-account webhook callbacks where depending on the incoming account submitting the loan application, we can configure a dedicated webhook callback endpoint per account so that multiple integrator accounts can be supported for a single installer partner.`}</p>
    <h2>{`HIC Upload, Add/Update Project Information, Verify Loan Details`}</h2>
    <p>{`The actions of uploading a Home Improvement Contract (HIC for short) and adding the system information (for Solar projects the system information includes inverter, panel, and optionally battery manufacturers as well as system size and estimated annual output) have been split out in v2 of the API and accomplished with separate API actions. In v1, these were accomplished with the `}<inlineCode parentName="p">{`POST /v1/applications/:loan-id/hic`}</inlineCode>{` resource, but now they will need to use `}<inlineCode parentName="p">{`GET /v2/loans/:id/uploadurl?supplemental= true|false&uploadType=HIC`}</inlineCode>{` for uploading the contract and `}<inlineCode parentName="p">{`PUT /v2/loans/:id/project`}</inlineCode>{` for updating the project (or system) information. This gives more flexibility for the client system to manage changes in either of these two distinct actions without needed to repeat information for the other.`}</p>
    <p>{`This step in the Management of a Loan Project workflow will also need to be accompanied by a Verify Loan Details action (`}<inlineCode parentName="p">{`POST /v2/loans/:id/details`}</inlineCode>{`) to make the project eligible for Contract Review on the GoodLeap side and move the project through Notice to Proceed (NTP). The Verify Loan Details action is also required for any changes to the loan offer, so if loan amount, term, rate, product, or promotions change.`}</p>
    <h2>{`API v1 Sunset Date`}</h2>
    <p>{`API v1 will be sunset on 12/31/2020.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;