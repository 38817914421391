
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const meta = {
  title: 'Error Codes',
  category: 'Guides',
  published: true,
  sort: 3
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Error Codes`}</h1>
    <p>{`These happen from time to time and can range from something that's invalid, or a dependency failure. We
will conform to the shape below. It will be JSON formatted, accompanied by a specific error code and friendly description explaining why the error has occurred:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
  {
    "description": "\\"offerId\\" is required",
    "type": "VALIDATION",
    "code": "BAD_INPUT"
  },
  {
    "description": "Maximum string length limit exceeded",
    "type": "VALIDATION",
    "code": "MAXIMUM_LENGTH_EXCEEDED"
  }
]
`}</code></pre>
    <p>{`We will be following typical protocols for which HTTP status code to provide given the error at hand.
Above, you will see a collection of errors, but they will always be distinct to a given status code.
You won't see a `}<inlineCode parentName="p">{`VALIDATION`}</inlineCode>{` mixed with `}<inlineCode parentName="p">{`SERVER`}</inlineCode>{` as an example, since one will be
`}<inlineCode parentName="p">{`40x`}</inlineCode>{` and the other is `}<inlineCode parentName="p">{`50x`}</inlineCode>{`. For `}<inlineCode parentName="p">{`400`}</inlineCode>{` error code enumerations, expand the 400 Bad request section of the resource you are attempting to use in the `}<a parentName="p" {...{
        "href": "/reference"
      }}>{`API Documentation`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;