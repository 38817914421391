
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const meta = {
  title: 'Solar Lease & Power Purchase Agreement',
  category: 'Coming Soon!',
  published: true,
  sort: 1
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`GoodLeap is developing a new product offering that allows organizations and integrations to offer solar leases and power purchase agreement products. The integration and organization must both be enabled and approved by GoodLeap to offer leases and power purchase agreements prior to being able to leverage the resources in the developerAPI`}</p>
    <p>{`Please reach out to your Customer Success Solution Architect for more information.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;