
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Image from 'next/image';
import submittingLoanApp from '../../../public/images/Submitting-A-Loan-Application-resize-2.png';
import managingLoanProject from '../../../public/images/Managing-a-Loan-Project-2-resize.png';
import createNewUser from '../../../public/images/Create-new-user.png';
import updateRoles from '../../../public/images/Update-Roles.png';
import submittingV2Loan from '../../../public/images/Submitting-A-V2-Loan.png';
import manageCases from '../../../public/images/Manage-Cases.png';
export const meta = {
  title: 'Workflow Diagrams',
  category: 'Guides',
  published: true,
  sort: 6
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Workflow Diagrams`}</h1>
    <p>{`These diagrams are meant to represent typical use cases of creating and managing users, submitting a loan application, and managing the loan project. These are not exhaustive, and the APIs can be used outside of these workflows, but they do provide a common starting place and template for how to integrate with the APIs.`}</p>
    <p>{`Once these workflows make sense, head over to the `}<a parentName="p" {...{
        "href": "/docs/solar-battery-workflow"
      }}>{`Solar & Battery Workflow Code Samples`}</a>{` or `}<a parentName="p" {...{
        "href": "/docs/hi-roofing-workflow"
      }}>{`Home Improvement & Roofing Code Samples`}</a>{` for a more in depth look at using the API for the loan categories you will be using.`}</p>
    <h2>{`User Management`}</h2>
    <p>{`How to create and manage users within your organization`}</p>
    <Image src={createNewUser} alt="Create new user" placeholder="blur" mdxType="Image" />
    <Image src={updateRoles} alt="Update a user role" placeholder="blur" mdxType="Image" />
    <h2>{`Submitting a Loan Application`}</h2>
    <p>{`How to submit a loan application, including handling promotions.`}</p>
    <Image src={submittingV2Loan} alt="Submitting a loan application" placeholder="blur" mdxType="Image" />
    <h2>{`Managing a Loan Project`}</h2>
    <p>{`How to submit a loan application, including handling promotions.`}</p>
    <Image src={managingLoanProject} alt="Managing a loan project" placeholder="blur" mdxType="Image" />
    <h2>{`Managing Cases`}</h2>
    <p>{`How to use the cases endpoints to manage conditions or stipulations associated with a case 'id' for a loan.`}</p>
    <Image src={manageCases} alt="Managing Cases" placeholder="blur" mdxType="Image" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;