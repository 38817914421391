
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const meta = {
  title: 'Authentication',
  category: 'Guides',
  published: true,
  sort: 2
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Authentication`}</h1>
    <p>{`To get started, obtain your Sandbox organization ID and API key GoodLeap's `}<a parentName="p" {...{
        "href": "mailto:cfappsupport@goodleap.com?subject=API%20V2%20Key%20Request&body=Installer%20Partner%20Name:%20%3C%3CINSERT%20PARTNER%20NAME%20HERE%3E%3E%0D%0AEnvironment:%20%3C%3CSandbox%20OR%20Production%3E%3E%0D%0ARelationship%20to%20Installer%20Partner:%20%3C%3CChoose%20one%20of%20the%20following:%20Partner%20Employee,%20Integrator%20for%20the%20Installer%20Partner,%20Other:%20Describe%20Relationship%3E%3E"
      }}>{`Customer Success Team`}</a>{`. Including the installer partner you are associated with in your communication. When you are ready to use the production environment, please make an additional request for a Production organization ID and API key with the same request form. The organization ID and API key will be different between Sandbox and Production so be sure you are using the correct credentials for each environment.`}</p>
    <ul>
      <li parentName="ul">{`Sandbox URL:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`https://sandbox01-api.goodleap.com/posfinancing/rest/v2/<insert resource name>`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`Production URL:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`https://api.goodleap.com/posfinancing/rest/v2/<insert resource name>`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <p>{`To access endpoints in the developer API, all requests must use Basic HTTP authentication. Use the organization ID as your "username", and the API key as your "password". In Basic HTTP authentication, a request contains a header field in the form of `}<inlineCode parentName="p">{`Authorization: Basic credentials`}</inlineCode>{`. The credentials are the Base64 encoding of ID and password joined by a single colon `}<inlineCode parentName="p">{`:`}</inlineCode>{`. In the example where the username is `}<inlineCode parentName="p">{`username`}</inlineCode>{` and password is `}<inlineCode parentName="p">{`password`}</inlineCode>{`, the header to use with your requests is `}<inlineCode parentName="p">{`Authorization: Basic dXNlcm5hbWU6cGFzc3dvcmQ`}</inlineCode>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;