import React from 'react';
import ErrorPage from 'next/error';
import Bugsnag from '../utils/bugsnag';

export const getIntialProps = (ctx: any) => {
  console.log(ctx);
  if (ctx.err) Bugsnag.notify(ctx.err);
  return ErrorPage.getInitialProps(ctx);
};

// @ts-ignore
const Page: React.FC<any> = ({ statusCode }) => <ErrorPage statusCode={statusCode || 500} />;
// const Page: React.FC<ErrorProps> = ({ statusCode }) =><div>test</div>;

export default Page;
