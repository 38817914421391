import Link from 'next/link';
import cookie from 'js-cookie';
import { useRouter } from 'next/router';
import { Box, Flex } from 'theme-ui';
import { Button, Logo } from '@loanpal/lumos__core';

type PropTypes = {
  handleRevalidate: () => Promise<boolean>;
};

const Navbar: React.FC<PropTypes> = ({ handleRevalidate }) => {
  const router = useRouter();
  const getLinkStyles = (path?: string) => {
    const isCurrentPath = Boolean((router.pathname === '/' && !path) || router.pathname.includes(`/${path}`));

    return {
      color: 'text.negative',
      borderBottom: '2px solid',
      borderColor: isCurrentPath ? 'logo.icon' : 'transparent',
      textDecoration: 'none',
      fontWeight: 600,
      py: '2px',
      mr: 4,
      ':hover': { textDecoration: 'none', borderColor: 'secondary.l2' },
    };
  };

  return (
    <Box
      as="nav"
      aria-label="Main"
      sx={{ bg: 'primary.d3', display: 'flex', alignItems: 'center', px: 3, py: 1, minHeight: 56 }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <Link href="/">
          <Box as="h1" sx={{ m: 0, p: 0, mr: [3, 4] }}>
            <Logo invert size="sm" sx={{ display: ['none', 'block'] }} />
            <Logo variant="icon" size="sm" invert sx={{ display: ['block', 'none'], ml: -2 }} />
          </Box>
        </Link>
        <Link href="/" passHref>
          <Box as="a" sx={getLinkStyles()}>
            Home
          </Box>
        </Link>
        <Link href="/docs" passHref>
          <Box as="a" sx={getLinkStyles('docs')}>
            Docs
          </Box>
        </Link>
        <Link href="/reference" passHref>
          <Box as="a" sx={getLinkStyles('reference')}>
            API
          </Box>
        </Link>
      </Flex>
      <Box sx={{ ml: 'auto' }}>
        <Button
          variant="ghost"
          sx={{ color: 'white', '&:hover': { color: 'primary.base' } }}
          onClick={() => {
            cookie.remove('token');
            handleRevalidate();
          }}
        >
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default Navbar;
