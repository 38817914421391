
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const meta = {
  title: "What's New",
  category: 'Updates',
  published: false,
  sort: 2
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`What's New`}</h1>
    <p>{`In this section we will document release information so you can stay up to date with all the latest changes.`}</p>
    <h2>{`r2020.10.22`}</h2>
    <h3>{`Release Items`}</h3>
    <ul>
      <li parentName="ul">{`API v2 endpoints`}
        <ul parentName="li">
          <li parentName="ul">{`All endpoints from r2020.10.07 now deployed to production`}</li>
          <li parentName="ul">{`GET /loans/:id/stipulations - Get Stipulations`}</li>
          <li parentName="ul">{`GET /v2/loans/:id/uploadurl?stipId=1234&uploadType=STIP&docType=DRIVERS_LICENSE (returns a pre-signed URL for Driver's License Upload) - Upload Stipulation artifact`}</li>
          <li parentName="ul">{`GET /v2/categories/:id/components - Fetch project component manufacturers`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Multi-account webhook support - depending on the incoming account submitting the loan application, we can configure a dedicated webhook callback endpoint per account so that multiple integrator accounts can be supported for a single installer partner`}</li>
      <li parentName="ul">{`Filter loan options by loan ID now only displays valid loan options for the loan based on metadata (state, channel, promotions)`}</li>
      <li parentName="ul">{`Project details component manufacturers now validated against enumerated list`}</li>
      <li parentName="ul">{`Sandbox only toolbox actions for clearing stips and completing contract review automatically`}</li>
    </ul>
    <h3>{`Known Issues`}</h3>
    <ul>
      <li parentName="ul">{`None`}</li>
    </ul>
    <h2>{`r2020.10.07 (Sandbox Only)`}</h2>
    <h3>{`Release Items`}</h3>
    <ul>
      <li parentName="ul">{`API v2 endpoints`}
        <ul parentName="li">
          <li parentName="ul">{`GET /v2/payments - Calculate monthly payments`}</li>
          <li parentName="ul">{`GET /v2/categories - Get Categories`}</li>
          <li parentName="ul">{`GET /v2/offers - Get available loan offers`}</li>
          <li parentName="ul">{`GET /v2/disclosures - Get disclosures`}</li>
          <li parentName="ul">{`GET /v2/promotions - Fetch available promotions`}</li>
          <li parentName="ul">{`POST /v2/loans - Submit a loan application`}</li>
          <li parentName="ul">{`GET /v2/loans/:id - Fetch loan by ID`}</li>
          <li parentName="ul">{`GET /v2/loans?referenceNumber= - Fetch loan by reference number (returns a collection)`}</li>
          <li parentName="ul">{`POST /v2/loans/:id/cancellations - Cancel a loan`}</li>
          <li parentName="ul">{`POST /v2/loans/:id/changeOrder - Submit a change order`}</li>
          <li parentName="ul">{`POST /v2/loans/:id/details - Submit loan detail verification`}</li>
          <li parentName="ul">{`GET /v2/loans/:id/documents - Send loan documents`}</li>
          <li parentName="ul">{`GET /v2/loans/:id/status - Get current loan status`}</li>
          <li parentName="ul">{`GET /v2/loans/:id/project - Return project details of a loan`}</li>
          <li parentName="ul">{`GET /v2/channels?state=‘’&states=[] - Fetch available channels (option to filter by states)`}</li>
          <li parentName="ul">{`GET /v2/states?channel=‘’&channels=[] - Fetch available states (option to filter by channels)`}</li>
          <li parentName="ul">{`GET /v2/loans/:id/uploadurl?supplemental= true|false&uploadType=HIC (returns a pre-signed URL for HIC) - Upload HIC`}</li>
          <li parentName="ul">{`PUT /v2/loans/:id/project - Update the project details of a loan`}</li>
          <li parentName="ul">{`POST /v2/loans/:id/milestones - Update milestones`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Soft Credit Check automatically activated on all v2 traffic`}</li>
      <li parentName="ul">{`Autopay enrollment made available for applicants to receive discounted rate if they opt in`}</li>
      <li parentName="ul">{`Battery retro-fit now available as a category to submit loan applications for`}</li>
    </ul>
    <h3>{`Known Issues`}</h3>
    <ul>
      <li parentName="ul">{`Query parameters with an array type (like states on `}<inlineCode parentName="li">{`GET /v2/channels`}</inlineCode>{`) require specified positions to execute, so adding `}<inlineCode parentName="li">{`?states[0]=TX&states[1]=UT`}</inlineCode>{` will work while leaving the parameters blank like this `}<inlineCode parentName="li">{`?states[]=TX&states[]=UT`}</inlineCode>{` will only use the last parameter. Because the use case will be driven by a single address and therefore single state and due to the framework limitation, we will continue to only support the single parameter.`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;