
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Table from '@loanpal/lumos__table';
export const meta = {
  title: 'Origin Loan Migration',
  category: 'Updates',
  published: true,
  sort: 2
};
export const data = [{
  endpoint: 'GET /v2/categories',
  change: 'Category IDs are now GUIDS'
}, {
  endpoint: 'GET /v2/promotions',
  change: "The list of promotions has changes, make sure to check your code's compatibility"
}, {
  endpoint: 'GET /v2/offers',
  change: "'offerId' has changed from a numeric string to a 36-character GUID"
}, {
  endpoint: 'GET /v2/loans',
  change: "The offerId will now return a 36-character GUID in line with 'GET /v2/offers' "
}, {
  endpoint: 'POST /v2/loans',
  change: "The 'offerId' field will accept both the numberic value and 36-character GUID values"
}, {
  endpoint: 'GET /v2/payments',
  change: <ul>
        <li>The offerId parameter can be both a 36-character GUID value or the numeric string value</li>
        <li> the payments endpoint will now only estimate a max of 20 offers per call</li>
      </ul>
}, {
  endpoint: 'GET /v2/loans/:id/project',
  change: 'Battery capacity and quantity are no longer used and will be empty on response. These values will be discarded if sent'
}, {
  endpoint: 'PUT /v2/loans/:id/project',
  change: 'Battery capacity and quantity are no longer used and will be empty on response. These values will be discarded if sent'
}, {
  endpoint: 'POST /v2/loans/:id/changeorders',
  change: "the 'offerId' field can be both a 36-character guid or a numeric string"
}];

const layoutProps = {
  meta,
data
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Origin Loans migration`}</h1>
    <p>{`Origin loan migration is intended to support the overall migration to the Origin platform and we are updating the API to facilitate this migration, but there are a few changes you will also need to make.`}</p>
    <p>{`When you are migrated to Origin, all new loans you create will only be visible in Origin, but all of your existing loans will also be visible in Origin no need to jump between platforms to find your loans. The developer API has also built in backwards compatibility so that you are able to continue managing your existing loans after the migration.`}</p>
    <p>{`We have made a few logistical changes that you should note, as you will need to code to these changes.`}</p>

    <Table columns={[{
      Header: 'Endpoint Path',
      accessor: 'endpoint'
    }, {
      Header: 'Changes',
      accessor: 'change'
    }]} data={data} mdxType="Table" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;