import React from 'react';
import { object, string } from 'yup';
import { Box } from 'theme-ui';
import cookie from 'js-cookie';
import { FormikHelpers } from 'formik';
import {
  Form,
  TextInput,
  SecretInput,
  Card,
  Heading,
  Button,
  AlertInline,
  VisuallyHidden,
} from '@loanpal/lumos__core';
import { AuthCodes } from '../types/error-codes';
import { HeroView } from '@loanpal/lumos__layout';

type FormInput = {
  username: string;
  password: string;
};

type PropTypes = {
  handleRevalidate: () => {};
};

const Login: React.FC<PropTypes> = ({ handleRevalidate }) => {
  const [systemError, setError] = React.useState<string | undefined>();
  const schema = object({
    username: string().required('Enter your username'),
    password: string().required('Enter your password'),
  });

  const handleSubmit = async (value: FormInput, helpers: FormikHelpers<FormInput>) => {
    setError(undefined);
    try {
      const res = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(value),
      });

      const body = await res.json();
      if (body.code === AuthCodes.INVALID_USER) {
        helpers.setErrors({ username: body.message });
        return;
      }

      if (body.code === AuthCodes.INVALID_PASS) {
        helpers.setErrors({ password: body.message });
        return;
      }

      if (body.token) {
        cookie.set('token', body.token, { expires: 20 });
        handleRevalidate();
      }
    } catch (err) {
      console.log(err);
      setError(
        `There's a technical problem that has prevented login. Try reloading this page. If that doesn't work, contact support or try again later.`
      );
    }
  };

  return (
    // @ts-ignore
    <HeroView>
      <Box
        sx={{
          pt: 6,
          '@media screen and (max-width: 80em)': {
            pt: 4,
          },
        }}
      >
        <Box sx={{ width: '100%', mx: 'auto', maxWidth: 480 }}>
          <Card variant="float" sx={{ px: 4 }}>
            <Heading variant="h2">Developer API</Heading>
            <section aria-describedby="loginTitle">
              {systemError && <AlertInline variant="danger">{systemError}</AlertInline>}
              <Form
                showSummary={false}
                onSubmit={handleSubmit}
                validationSchema={schema}
                initialValues={{ username: '', password: '' }}
              >
                <VisuallyHidden>
                  <Heading id="loginTitle" variant="h2">
                    Sign in
                  </Heading>
                </VisuallyHidden>
                <TextInput
                  name="username"
                  label="Username"
                  autoComplete="username"
                  autoCapitalize="none"
                  autoCorrect="off"
                  spellCheck="false"
                />
                <SecretInput
                  name="password"
                  label="Password"
                  autoComplete="current-password"
                  autoCorrect="off"
                  spellCheck="false"
                  autoCapitalize="none"
                />
                <Box sx={{ display: 'flex', mb: 3 }}>
                  <Button type="submit">Sign In</Button>
                  <Button
                    as="a"
                    href="mailto:cfappsupport@goodleap.com?subject=API%20V2%20Support%20Request&body=Installer%20Partner%20Name:%20<<INSERT%20PARTNER%20NAME%20HERE>>%0D%0ARelationship%20to%20Installer%20Partner:%20<<Choose%20one%20of%20the%20following:%20Partner%20Employee,%20Integrator%20for%20the%20Installer%20Partner,%20Other:%20Describe%20Relationship>>%0D%0A%0D%0ASupport%20Request%20Needs:%20<<INSERT%20REQUEST%20HERE>>"
                    variant="secondary"
                    sx={{ ml: 2 }}
                  >
                    Request access
                  </Button>
                </Box>
              </Form>
            </section>
          </Card>
        </Box>
      </Box>
    </HeroView>
  );
};

export default Login;
