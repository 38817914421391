
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const meta = {
  title: 'Promotions',
  category: 'Updates',
  published: true,
  sort: 4
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Showcasing Promotions`}</h1>
    <h2>{`Walkthrough`}</h2>
    <p>{`Sometimes, you may want to showcase a specific promotion. To achieve this, you would need to interact with two different resources: `}<inlineCode parentName="p">{`/rest/v2/promotions`}</inlineCode>{` and `}<inlineCode parentName="p">{`/rest/v2/offers`}</inlineCode>{`.`}</p>
    <p>{`Make a `}<inlineCode parentName="p">{`GET`}</inlineCode>{` request to `}<inlineCode parentName="p">{`/rest/v2/promotions`}</inlineCode>{` to ensure the client's promotion still exists:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const axios = require('axios');
const superCoolPromo = '7d448ebb-3c62-46f7-9f16-82d46d955bc9';
const config = {
  method: 'GET',
  url: 'https://api.goodleap.com/rest/v2/promotions',
  headers: {
    Authorization: 'EEAZfkqJg48DTvJ6KHIEP9m3PmdL7aZd5ww3qejk',
    'Content-Type': 'application/json',
  },
};

try {
  const { data } = await axios(config);
  const promo = data?.data?.find((datum) => datum.id === superCoolPromo);
  if (promo) console.log('Found it', promo);
} catch (e) {
  console.log(e, 'Nooo something went wrong');
}
`}</code></pre>
    <p>{`If it exists, you should have received a promotion like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "7d448ebb-3c62-46f7-9f16-82d46d955bc9",
  "name": "ACH enrollment",
  "description": "Take .5% off your options rate when you enroll in ACH."
}
`}</code></pre>
    <p>{`Now that we know the promo exists, let's get the offers:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const axios = require('axios');
const superCoolPromo = '7d448ebb-3c62-46f7-9f16-82d46d955bc9';
const config = {
  method: 'get',
  url: 'https://api.goodleap.com/rest/v2/offers?promotionIds=7d448ebb-3c62-46f7-9f16-82d46d955bc9',
  headers: {
    'Authorization': 'EEAZfkqJg48DTvJ6KHIEP9m3PmdL7aZd5ww3qejk',
    'Content-Type': 'application/json'
  }
};

try {
  const { data } = await axios(config);
  console.log('Got some!', data?.data?);
} catch(e) {
  console.log(e, "Nooo something went wrong");
}
`}</code></pre>
    <p>{`The result will be a collection of offers with that promotion applied.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
  {
    "id": "83646706-72bf-4edf-a183-43c1a27b5c2c",
    "name": "25 year - 2.99% with Autopay",
    "rate": 2.99,
    "term": 25,
    "installationCategoryId": "9cb8c2e2-843e-4786-a8d3-99d7bd3c6863",
    "promotionIds": ["7d448ebb-3c62-46f7-9f16-82d46d955bc9"],
    "channels": [],
    "states": [],
    "meta": {
      "cursor": "123nknj"
    }
  }
]
`}</code></pre>
    <h3>{`Enrolling in the Autopay Promotion`}</h3>
    <p>{`To enroll in the Autopay promotion described above, pass in `}<inlineCode parentName="p">{`AUTOPAY`}</inlineCode>{` in the collection of `}<inlineCode parentName="p">{`enrollments`}</inlineCode>{` when creating a loan with `}<inlineCode parentName="p">{`POST /v2/loans`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  <<< POST /loans input data >>>,
  "enrollments": [
    "AUTOPAY"
  ]
}
`}</code></pre>
    <p>{`The Autopay promotion enrollment can be added or removed throughout the management of the loan project by using the change order action (make a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request to `}<inlineCode parentName="p">{`/v2/loans/:id/changeOrder`}</inlineCode>{`).`}</p>
    <p>{`To add the Autopay promotion to a loan that isn't enrolled, use this body...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "changes": [
    {
      "type": "ENROLLMENT",
      "value": [
        {
          "type": "ADD",
          "value": "AUTOPAY"
        }
      ]
    }
  ]
}
`}</code></pre>
    <p>{`To remove the Autopay promotion from a loan that is enrolled, use this body...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "changes": [
    {
      "type": "ENROLLMENT",
      "value": [
        {
          "type": "REMOVE",
          "value": "AUTOPAY"
        }
      ]
    }
  ]
}
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;