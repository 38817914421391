import React from 'react';
import Head from 'next/head';
import Error from './_error';
import { Router } from 'next/router';
import useSWR from 'swr';
import { MDXProvider, MDXProviderComponents } from '@mdx-js/react';

import { Box, useThemeUI } from 'theme-ui';
import ThemeProvider, { GoodLeap } from '@loanpal/lumos__theme';
import { Navbar, Highlight, Login, Sidebar } from '../widgets';

import { GTMPageView } from '../utils/gtm';
import Bugsnag from '../utils/bugsnag';

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React) as any;

export const getInitialProps = async ({ Component, router, ctx }) => {
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return { pageProps };
};

const MyApp = ({ Component, pageProps, router }) => {
  const { data, revalidate } = useSWR('/api/me', async (args) => {
    const res = await fetch(args);
    return res.json();
  });

  React.useEffect(() => {
    const handleRouteChange = (url: string) => GTMPageView(url);
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  if (!data) return <p>Loading....</p>;
  const loggedIn = data.message !== 'Unable to authorize';

  const loggedInView = (
    <Box sx={{ maxWidth: 2000, boxShadow: 2, minHeight: '100vh' }} bg="white" mx="auto">
      <Navbar handleRevalidate={revalidate} />
      {router.asPath.includes('/docs') ? (
        <Sidebar>
          <Component {...pageProps} />
        </Sidebar>
      ) : (
        <Component {...pageProps} />
      )}
    </Box>
  );

  return (
    <ThemeProvider theme={GoodLeap}>
      <ErrorBoundary FallbackComponent={Error}>
        <MDXProvider
          components={
            {
              code: Highlight,
              wrapper: (props) => (
                <Box
                  as="main"
                  sx={{
                    maxWidth: 1000,
                    mx: 'auto',
                    pt: [3, 4],
                    pb: [3, 5],
                    px: 3,
                    lineHeight: 1.9,
                    code: {
                      bg: 'neutral.l3',
                      p: '2px 3px',
                      fontFamily: '"Fira Code", monospace',
                      fontWeight: 500,
                    },
                  }}
                  {...props}
                />
              ),
            } as MDXProviderComponents
          }
        >
          <Box bg="neutral.l4">
            <Head>
              <title>GoodLeap | Developer API</title>
            </Head>
            {loggedIn ? loggedInView : <Login handleRevalidate={revalidate} />}
          </Box>
        </MDXProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default MyApp;
