import Highlight, { defaultProps } from 'prism-react-renderer';
import dracula from 'prism-react-renderer/themes/vsDark';

const MarkdownHighlight = ({ children, className }) => {
  const language = className.replace(/language-/, '');

  return (
    // @ts-ignore
    <Highlight {...defaultProps} code={children} language={language} theme={dracula}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          className={className}
          style={{ ...style, padding: '10px 20px 0', display: 'block', overflowX: 'scroll' }}
        >
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};

export default MarkdownHighlight;
