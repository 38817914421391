import React from 'react';
import Link from 'next/link';
import { Box, Grid } from 'theme-ui';

import { posts } from '../data/get-all-posts';

const Sidebar = ({ children }) => {
  const initialData = React.useMemo(() => {
    const postObject = posts.reduce((acc, curr, i) => {
      const { meta } = curr.module;
      const key = meta.category;
      if (!meta.published) return acc;
      if (acc[key]) {
        acc[key].children.push({
          name: meta.title,
          link: curr.link,
          sort: meta.sort,
        });
      } else {
        acc[key] = {
          name: meta.category,
          children: [
            {
              name: meta.title,
              link: curr.link,
              sort: meta.sort,
            },
          ],
        };
      }

      return acc;
    }, {});
    return Object.values(postObject);
  }, []);
  return (
    <Grid columns={[1, '300px 1fr']} sx={{ minHeight: '100vh' }}>
      <Box as="aside" py={4} px={3} bg="neutral.l4">
        {initialData.map((detail: any, i) => (
          <details open={i === 0}>
            <Box as="summary" sx={{ lineHeight: '1.8rem', fontSize: 3 }}>
              {detail.name}
            </Box>
            <Box as="ul" sx={{ listStyleType: 'none', ml: 2, px: 0, py: 1 }}>
              {detail.children
                .sort((a, b) => a.sort - b.sort)
                .map((x) => (
                  <Box as="li" sx={{ lineHeight: '1.8rem', fontSize: 2 }}>
                    <Link href={`/docs${x.link.replace('.mdx', '')}`}>{x.name}</Link>
                  </Box>
                ))}
            </Box>
          </details>
        ))}
      </Box>
      <Box sx={{ maxWidth: 1080, mx: 'auto', px: 3, width: '100%' }}>{children}</Box>
    </Grid>
  );
};

export default Sidebar;
