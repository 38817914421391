function importAll(r) {
  return r.keys().map((fileName) => ({
    link: fileName.substr(1).replace(/\/index\.mdx$/, ''),
    module: r(fileName),
  }));
}

type Post = {
  link: string;
  module: {
    meta: {
      title: string;
      category: string;
      published: boolean;
      sort?: number;
    };
  };
};

// @ts-ignore
export const posts: Post[] = importAll(require.context('../pages/docs/', true, /\.mdx$/));
