
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const meta = {
  title: 'Testing',
  category: 'Guides',
  published: true,
  sort: 4
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Testing`}</h1>
    <p>{`To make testing easy, we've setup a Postman collection with all v2 calls preconfigured.`}</p>
    <p><a parentName="p" {...{
        "href": "https://app.getpostman.com/run-collection/18841784-302c98bf-6378-4e45-94c8-12fe90a6d548"
      }}><img parentName="a" {...{
          "src": "/images/RunInPostman.png",
          "alt": "button"
        }}></img></a></p>
    <h1>{`Test Cases`}</h1>
    <p>{`Having a broad range of scenario coverage and predictable responses from the `}<inlineCode parentName="p">{`POST /v2/loans`}</inlineCode>{` resource is an important part of testing any API. We've implemented the following set of SSN range driven test cases to cover scenarios. Other required test case identity information besides the SSN must be fictitious/made up (e.g. Name: John Doe, Address: 123 Main St. Roseville, CA 95661, DOB: 1/1/1980, etc.).`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Single Applicant Test Scenarios`}</strong>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`SSN Range`}</strong>{` - Any digits in the SSN range indicated by an `}<inlineCode parentName="li">{`x`}</inlineCode>{` character are wildcards and any number can be accepted in that position. Access will still be availible for the corresponding test .`}</li>
          <li parentName="ul"><strong parentName="li">{`4 Digit SSN`}</strong>{` - The `}<inlineCode parentName="li">{`ssn`}</inlineCode>{` field supports full 9-digit SSNs or the last 4-digits of the SSN. This field represents the 4 digit SSN to access the corresponding test scenario`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Co-Applicant Test Scenarios`}</strong>{` - Co-applicant scenarios are created by combining scenarios from the single applicant list`}</li>
      <li parentName="ul"><strong parentName="li">{`APPLICANT_HAS_ACTIVE_LOAN Check`}</strong>{` - One of the pre-screen rules checks for duplicate active loans for an applicant. If dupplicate active loans appear during testing, it can be bypassed by incrementing the value of the street address: (submit your first test case with 123 Main St and your second test case with 124 Main St)`}</li>
    </ul>
    <p>{`Use the sandbox environments for all testing purposes:`}</p>
    <p><a parentName="p" {...{
        "href": "https://sandbox01-api.goodleap.com/posfinancing/rest/v2/"
      }}>{`https://sandbox01-api.goodleap.com/posfinancing/rest/v2/`}</a>{`...`}</p>
    <h2>{`Single Applicant Test Scenarios`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Solar Max Loan Amount (Battery)`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Non-Deferred Stipulation`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Deferred Stipulation`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SSN Range`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`4 Digit SSN`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Other Inputs Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`APPROVED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$100,000 ($50,000)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500101xxx`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`0101`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`none`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`APPROVED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$100,000 ($50,000)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`ID_VALIDATION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50010221x`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`0124`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`none`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`APPROVED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$50,000 ($35,000)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500301xxx`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`0112`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Income >= \\$50,000`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`APPROVED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$50,000 ($35,000)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`ID_VALIDATION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50030225x`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`0141`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Income >= \\$50,000`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CONDITIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$50,000 ($35,000)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`MORTGAGE_PAYMENT `}{`_`}{`VERIFICATION`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5003031xx`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`0189`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Income >= \\$50,000`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CONDITIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$50,000 ($35,000)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`INCOME_VERIFICATION`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50030241x`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`0146`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Income >= \\$260,000`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DECLINED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500205xxx`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`0162`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`none`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PENDING`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500304xxx`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`0142`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`none`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Co-Applicant Test Scenarios`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Applicant SSN`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Co-Applicant SSN`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`COMBINED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50010221x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500101xxx`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PRIMARY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500101xxx`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500205xxx`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SECONDARY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500205xxx`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500101xxx`}</td>
        </tr>
      </tbody>
    </table>
    <h1>{`Forcing Workflow Actions`}</h1>
    <p>{`There are a few key points in the workflow of a loan project that in the production environment requires GoodLeap team members to complete actions. So that you are able to fully utilize the test environment to simulate these flows in production, we have created a toolbox API that will allow clients to complete these actions directly via API calls. Here are the details of those actions.`}</p>
    <h2>{`Clear Stipulations`}</h2>
    <p>{`To clear stipulations on a loan, make the following API call.`}</p>
    <p><inlineCode parentName="p">{`POST https://sandbox01-api.goodleap.com/toolbox/rest/v2/loans/:id/clear`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "what": "STIPULATIONS"
}
`}</code></pre>
    <h2>{`Clear Contract Review`}</h2>
    <p>{`To clear contract review on a loan, make the following API call.`}</p>
    <p><inlineCode parentName="p">{`POST https://sandbox01-api.goodleap.com/toolbox/rest/v2/loans/:id/clear`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "what": "CONTRACT_REVIEW"
}
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;