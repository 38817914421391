import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: '29e4706973f73ccc9424e65ad0787941',
  plugins: [new BugsnagPluginReact(React)],
  releaseStage: process.env.NEXT_PUBLIC_STAGE,
});

export default Bugsnag;
